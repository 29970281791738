@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "cal-sans";
    src: url("/fonts/CalSans-SemiBold.woff2") format("woff2");
    font-display: swap;
}

@layer base {
    html {
        @apply font-secondary bg-cal-gray;
    }
    h1 {
        @apply text-7xl font-bold font-primary font-cal-sans;
    }
    h2 {
        @apply text-5xl font-bold font-secondary font-cal-sans;
    }
    h3 {
        @apply text-2xl font-tertiary;
    }
    button:not([data-state]) {
        @apply border-2 border-gray-900 disabled:border-gray-400 font-primary disabled:font-tertiary font-medium text-xl p-3 h-14 w-full max-w-md rounded-[2rem] enabled:hover:rounded-2xl transition-rounded flex items-center justify-center;
    }
    button > span {
        @apply grow;
    }
    button.primary {
        @apply bg-gray-900 text-white disabled:bg-gray-300;
    }
    select {
        @apply rounded-[2rem] enabled:hover:rounded-2xl text-center bg-gray-300 enabled:hover:bg-gray-400 transition-all p-3 max-w-xs w-full font-secondary text-ellipsis text-xl;
    }
    input {
        @apply w-full bg-transparent grow p-3 text-ellipsis;
    }
    code {
        @apply bg-gray-300 py-0.5 px-1 rounded-md max-w-min;
    }
    a {
        @apply hover:font-tertiary font-semibold transition-colors;
    }
    ul {
        @apply space-y-6;
    }
    .center {
        @apply flex flex-col justify-center items-center;
    }
    .font-primary {
        @apply text-gray-900;
    }
    .font-secondary {
        @apply text-gray-700;
    }
    .font-tertiary {
        @apply text-gray-500;
    }
    .font-negative {
        @apply text-gray-300;
    }
    .font-cal-sans {
        font-family: "cal-sans", Arial, Helvetica, sans-serif;
    }
}

